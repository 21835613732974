exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-dashboard-endpoints-index-tsx": () => import("./../../../src/pages/dashboard/endpoints/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-endpoints-index-tsx" */),
  "component---src-pages-dashboard-groups-index-tsx": () => import("./../../../src/pages/dashboard/groups/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-groups-index-tsx" */),
  "component---src-pages-endpointimport-index-tsx": () => import("./../../../src/pages/endpointimport/index.tsx" /* webpackChunkName: "component---src-pages-endpointimport-index-tsx" */),
  "component---src-pages-endpoints-endpoint-id-index-tsx": () => import("./../../../src/pages/endpoints/[endpointId]/index.tsx" /* webpackChunkName: "component---src-pages-endpoints-endpoint-id-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oauthredirect-index-tsx": () => import("./../../../src/pages/oauthredirect/index.tsx" /* webpackChunkName: "component---src-pages-oauthredirect-index-tsx" */),
  "component---src-pages-theme-index-tsx": () => import("./../../../src/pages/theme/index.tsx" /* webpackChunkName: "component---src-pages-theme-index-tsx" */)
}

